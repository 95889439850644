import React from 'react';

const SunnysidePineLogo = ({
    xmlns='http://www.w3.org/2000/svg',
    height = 323,
    width = 1008,
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 1008 323" fill="none" xmlns={xmlns}>
        <path d="M615.563 2.20486e-06C572.902 0.00500241 531.091 11.9343 494.85 34.4414C458.608 56.9485 429.379 89.1374 410.462 127.375C410.194 127.914 410.067 128.513 410.094 129.114C410.122 129.716 410.302 130.3 410.618 130.813C410.934 131.326 411.375 131.749 411.9 132.044C412.425 132.339 413.016 132.495 413.618 132.498H817.536C818.142 132.497 818.738 132.342 819.266 132.045C819.795 131.749 820.239 131.322 820.555 130.806C820.872 130.289 821.051 129.7 821.075 129.095C821.099 128.489 820.967 127.888 820.693 127.348C801.772 89.1086 772.538 56.9208 736.291 34.4182C700.044 11.9155 658.227 -0.00591926 615.563 2.20486e-06Z" fill="#FFE636"/>
        <path d="M49.7345 276.223C24.1764 276.223 3.90711 263.651 0.058164 242.994C-0.0433914 242.394 -0.0116729 241.778 0.151076 241.192C0.313825 240.605 0.603625 240.061 0.99994 239.599C1.39625 239.136 1.88939 238.767 2.44442 238.516C2.99945 238.265 3.60279 238.14 4.2117 238.149H22.8472C23.7544 238.159 24.6341 238.461 25.3569 239.01C26.0797 239.558 26.6074 240.324 26.8623 241.194C30.0467 251.606 39.7937 255.538 50.3437 255.538C61.6136 255.538 69.3669 251.08 69.3669 243.077C69.3669 235.075 64.2165 230.644 52.0051 227.571L32.6219 222.42C12.408 217.242 2.55028 204.09 2.55028 186.949C2.55028 167.206 19.9398 154.053 46.2455 154.053C69.5607 154.053 86.341 165.96 90.3561 184.291C90.475 184.899 90.4575 185.525 90.3049 186.125C90.1523 186.725 89.8684 187.283 89.4736 187.76C89.0789 188.237 88.5832 188.62 88.0223 188.882C87.4614 189.144 86.8493 189.279 86.2303 189.275H68.5362C67.6802 189.269 66.8468 189 66.1486 188.505C65.4504 188.009 64.9209 187.312 64.6318 186.506C63.2109 182.833 60.6579 179.708 57.3424 177.582C54.027 175.456 50.1211 174.441 46.1901 174.682C36.3324 174.682 29.0499 179.168 29.0499 186.672C29.0499 194.176 34.9202 198.192 44.7779 200.767L64.5211 205.945C85.6765 211.483 95.7557 223.556 95.7557 241.416C95.7557 264.26 76.0402 276.223 49.7345 276.223Z" fill="#052C18"/>
        <path d="M112.648 156.549H130.093C131.204 156.549 132.271 156.988 133.059 157.772C133.848 158.555 134.295 159.619 134.302 160.73V222.562C134.302 241.336 142.609 252.856 157.783 252.856C174.397 252.856 186.443 239.453 186.443 214.338V160.592C186.486 159.504 186.948 158.476 187.733 157.722C188.517 156.969 189.563 156.548 190.651 156.549H208.096C209.203 156.556 210.262 156.999 211.045 157.781C211.827 158.564 212.27 159.623 212.278 160.73V269.802C212.278 270.913 211.838 271.98 211.055 272.768C210.271 273.557 209.208 274.004 208.096 274.011H190.651C189.535 274.011 188.465 273.567 187.675 272.778C186.886 271.989 186.443 270.918 186.443 269.802C186.453 268.995 186.23 268.203 185.801 267.52C185.371 266.837 184.753 266.293 184.022 265.954C183.29 265.614 182.476 265.494 181.677 265.607C180.878 265.719 180.129 266.061 179.52 266.59C171.943 273.151 162.18 276.629 152.162 276.337C125.136 276.337 108.467 256.954 108.467 226.771V160.592C108.51 159.509 108.968 158.485 109.747 157.732C110.526 156.979 111.565 156.555 112.648 156.549Z" fill="#052C18"/>
        <path d="M239.546 156.588H256.964C258.075 156.588 259.141 157.028 259.93 157.811C260.719 158.595 261.165 159.658 261.172 160.77V161.074C261.176 161.887 261.415 162.682 261.861 163.362C262.306 164.042 262.939 164.578 263.683 164.906C264.427 165.234 265.25 165.34 266.053 165.21C266.855 165.08 267.603 164.721 268.206 164.175C276.265 157.434 286.5 153.851 297.004 154.096C324.251 154.096 341.862 173.479 341.862 203.191V269.648C341.862 270.2 341.753 270.748 341.541 271.258C341.33 271.769 341.02 272.233 340.629 272.624C340.238 273.015 339.774 273.325 339.264 273.536C338.753 273.748 338.206 273.857 337.653 273.857H320.236C319.683 273.857 319.136 273.748 318.625 273.536C318.114 273.325 317.65 273.015 317.26 272.624C316.869 272.233 316.559 271.769 316.347 271.258C316.136 270.748 316.027 270.2 316.027 269.648V207.428C316.027 188.848 306.64 177.578 291.604 177.578C274.436 177.578 261.283 190.98 261.283 216.122V269.703C261.283 270.819 260.84 271.89 260.05 272.679C259.261 273.469 258.191 273.912 257.074 273.912H239.657C238.541 273.912 237.47 273.469 236.681 272.679C235.892 271.89 235.448 270.819 235.448 269.703V160.631C235.49 159.563 235.937 158.55 236.698 157.8C237.459 157.049 238.478 156.616 239.546 156.588Z" fill="#052C18"/>
        <path d="M367.309 156.577H384.754C385.861 156.584 386.92 157.027 387.703 157.81C388.485 158.592 388.928 159.652 388.936 160.758V161.063C388.939 161.876 389.178 162.671 389.624 163.351C390.069 164.031 390.702 164.567 391.446 164.895C392.19 165.223 393.013 165.329 393.816 165.199C394.618 165.069 395.366 164.71 395.969 164.164C404.021 157.502 414.208 153.972 424.656 154.224C451.903 154.224 469.514 173.607 469.514 203.318V269.775C469.514 270.886 469.075 271.953 468.291 272.741C467.508 273.53 466.444 273.977 465.333 273.984H447.888C446.772 273.984 445.701 273.54 444.912 272.751C444.123 271.962 443.679 270.891 443.679 269.775V207.417C443.679 188.836 434.292 177.566 419.256 177.566C402.088 177.566 388.936 190.969 388.936 216.111V269.692C388.936 270.803 388.496 271.87 387.713 272.658C386.929 273.447 385.866 273.894 384.754 273.901H367.309C366.193 273.901 365.123 273.457 364.333 272.668C363.544 271.879 363.101 270.808 363.101 269.692V160.62C363.144 159.533 363.606 158.504 364.391 157.751C365.175 156.997 366.221 156.576 367.309 156.577Z" fill="#052C18"/>
        <path d="M499.954 317.184L521.608 269.418C521.855 268.875 521.983 268.285 521.983 267.688C521.983 267.091 521.855 266.501 521.608 265.957L474.535 162.506C474.241 161.868 474.114 161.165 474.164 160.464C474.214 159.764 474.44 159.086 474.821 158.496C475.201 157.905 475.725 157.42 476.343 157.085C476.961 156.751 477.653 156.577 478.356 156.581H497.49C498.308 156.575 499.11 156.811 499.795 157.259C500.48 157.707 501.017 158.348 501.339 159.1L532.573 230.846C532.894 231.602 533.43 232.246 534.114 232.699C534.799 233.152 535.602 233.394 536.422 233.394C537.243 233.394 538.046 233.152 538.73 232.699C539.415 232.246 539.951 231.602 540.271 230.846L571.284 159.1C571.612 158.352 572.151 157.716 572.834 157.268C573.518 156.821 574.316 156.582 575.133 156.581H594.517C595.219 156.577 595.912 156.751 596.53 157.085C597.147 157.42 597.671 157.905 598.052 158.496C598.433 159.086 598.659 159.764 598.709 160.464C598.759 161.165 598.631 161.868 598.338 162.506L526.814 320.535C526.483 321.27 525.946 321.894 525.268 322.332C524.589 322.769 523.799 323.001 522.993 322.999H503.803C503.115 322.992 502.439 322.817 501.834 322.488C501.23 322.159 500.715 321.687 500.335 321.114C499.955 320.54 499.722 319.881 499.655 319.196C499.589 318.511 499.692 317.82 499.954 317.184Z" fill="#052C18"/>
        <path d="M652.663 276.223C627.133 276.223 606.863 263.651 602.987 242.994C602.89 242.395 602.925 241.781 603.09 241.196C603.255 240.612 603.545 240.07 603.94 239.609C604.335 239.147 604.827 238.778 605.379 238.526C605.932 238.274 606.533 238.145 607.14 238.149H625.859C626.771 238.154 627.657 238.453 628.385 239.001C629.114 239.55 629.645 240.319 629.902 241.194C633.086 251.606 642.833 255.538 653.383 255.538C664.653 255.538 672.406 251.08 672.406 243.077C672.406 235.075 667.228 230.644 655.017 227.571L635.772 222.42C615.558 217.242 605.7 204.09 605.7 186.949C605.7 167.206 623.062 154.053 649.396 154.053C672.711 154.053 689.491 165.96 693.506 184.291C693.626 184.901 693.608 185.53 693.453 186.132C693.299 186.734 693.013 187.294 692.615 187.771C692.217 188.248 691.718 188.631 691.154 188.891C690.589 189.152 689.974 189.283 689.353 189.275H671.492C670.635 189.263 669.8 188.992 669.099 188.498C668.397 188.004 667.861 187.31 667.56 186.506C666.148 182.833 663.601 179.706 660.289 177.579C656.978 175.453 653.074 174.438 649.146 174.682C639.289 174.682 632.006 179.168 632.006 186.672C632.006 194.176 637.876 198.192 647.734 200.767L667.477 205.945C688.605 211.483 698.712 223.556 698.712 241.416C698.712 264.26 679.107 276.223 652.663 276.223Z" fill="#052C18"/>
        <path d="M717.272 156.581H734.69C735.801 156.581 736.867 157.02 737.656 157.804C738.445 158.587 738.891 159.65 738.898 160.762V269.834C738.898 270.95 738.455 272.021 737.666 272.81C736.876 273.599 735.806 274.043 734.69 274.043H717.272C716.156 274.043 715.086 273.599 714.296 272.81C713.507 272.021 713.063 270.95 713.063 269.834V160.623C713.106 159.536 713.569 158.508 714.353 157.754C715.138 157 716.184 156.58 717.272 156.581Z" fill="#052C18"/>
        <path d="M810.072 154.064C821.221 154.069 832.035 157.878 840.725 164.864C841.336 165.379 842.081 165.71 842.873 165.817C843.665 165.925 844.472 165.804 845.198 165.47C845.924 165.136 846.54 164.602 846.974 163.931C847.408 163.259 847.641 162.479 847.648 161.679V101.896C847.648 100.78 848.091 99.7091 848.88 98.9198C849.67 98.1304 850.74 97.687 851.857 97.687H869.274C870.39 97.687 871.46 98.1304 872.25 98.9198C873.039 99.7091 873.483 100.78 873.483 101.896V269.671C873.483 270.787 873.039 271.858 872.25 272.647C871.46 273.437 870.39 273.88 869.274 273.88H851.857C850.74 273.88 849.67 273.437 848.88 272.647C848.091 271.858 847.648 270.787 847.648 269.671V268.674C847.647 267.873 847.417 267.088 846.985 266.413C846.553 265.737 845.937 265.199 845.21 264.862C844.483 264.524 843.675 264.402 842.88 264.508C842.085 264.614 841.338 264.945 840.725 265.462C832.034 272.439 821.217 276.231 810.072 276.206C777.868 276.206 753.445 248.737 753.445 215.149C753.445 181.561 777.868 154.064 810.072 154.064ZM814.752 177.546C793.845 177.546 779.751 193.994 779.751 215.149C779.751 236.304 793.845 252.725 814.752 252.725C835.658 252.725 849.752 236.277 849.752 215.149C849.752 194.021 835.658 177.546 814.779 177.546H814.752Z" fill="#052C18"/>
        <path d="M948.693 276.226C913.693 276.226 888.55 250.391 888.55 215.169C888.55 180.39 912.751 154.084 948.942 154.084C983.583 154.084 1008.2 179.643 1007.81 217.274C1007.81 218.392 1007.37 219.466 1006.58 220.26C1005.79 221.053 1004.72 221.503 1003.6 221.51H918.926C918.299 221.51 917.68 221.65 917.114 221.918C916.547 222.186 916.048 222.576 915.65 223.061C915.253 223.546 914.968 224.113 914.816 224.721C914.665 225.329 914.65 225.963 914.772 226.578C917.791 241.946 930.75 253.465 949.635 253.465C961.763 253.465 972.451 248.896 978.183 239.01C978.547 238.391 979.071 237.879 979.698 237.529C980.326 237.178 981.036 237.001 981.755 237.017H1000.34C1001.04 237.021 1001.73 237.2 1002.35 237.538C1002.96 237.876 1003.49 238.362 1003.87 238.953C1004.25 239.544 1004.48 240.22 1004.53 240.922C1004.59 241.623 1004.47 242.327 1004.18 242.97C995.711 262.104 975.774 276.226 948.693 276.226ZM920.587 203.179H974.417C975.081 203.172 975.735 203.009 976.324 202.703C976.914 202.398 977.423 201.957 977.811 201.418C978.199 200.879 978.455 200.257 978.558 199.601C978.661 198.944 978.609 198.273 978.405 197.641C976.45 191.199 972.443 185.572 966.994 181.618C961.545 177.664 954.953 175.599 948.222 175.738C933.768 175.738 921.557 183.215 916.572 197.669C916.373 198.302 916.325 198.972 916.432 199.627C916.539 200.281 916.798 200.902 917.189 201.438C917.579 201.974 918.09 202.411 918.681 202.713C919.271 203.016 919.924 203.175 920.587 203.179Z" fill="#052C18"/>
    </svg>
);

export default SunnysidePineLogo;