import * as React from 'react';

const StarRatings = () => (
    <svg width="91" height="18" viewBox="0 0 91 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_319_1376)">
            <g filter="url(#filter0_d_319_1376)">
                <path d="M8.6776 0.423828L11.33 5.8965L17.3552 6.72883L12.9701 10.9432L14.0404 16.9301L8.6776 14.0614L3.31412 16.9301L4.38508 10.9432L0 6.72883L6.02514 5.8965L8.6776 0.423828Z" fill="#FFE636" />
                <path d="M8.6776 1.1333L11.0516 6.03146L11.1238 6.18037L11.2877 6.20302L16.6806 6.948L12.7557 10.7201L12.6364 10.8348L12.6655 10.9977L13.6234 16.3561L8.82355 13.7885L8.67761 13.7105L8.53166 13.7885L3.73114 16.3561L4.68968 10.9977L4.71882 10.8348L4.5995 10.7201L0.674605 6.948L6.06749 6.20302L6.23142 6.18037L6.30359 6.03146L8.6776 1.1333Z" stroke="#CDA30D" strokeWidth="0.618864" />
            </g>
        </g>
        <g clipPath="url(#clip1_319_1376)">
            <g filter="url(#filter1_d_319_1376)">
                <path d="M27.2435 0.423828L29.896 5.8965L35.9211 6.72883L31.536 10.9432L32.6063 16.9301L27.2435 14.0614L21.88 16.9301L22.951 10.9432L18.5659 6.72883L24.5911 5.8965L27.2435 0.423828Z" fill="#FFE636" />
                <path d="M27.2435 1.1333L29.6175 6.03146L29.6897 6.18037L29.8536 6.20302L35.2465 6.948L31.3216 10.7201L31.2023 10.8348L31.2314 10.9977L32.1893 16.3561L27.3895 13.7885L27.2435 13.7105L27.0976 13.7885L22.2971 16.3561L23.2556 10.9977L23.2847 10.8348L23.1654 10.7201L19.2405 6.948L24.6334 6.20302L24.7973 6.18037L24.8695 6.03146L27.2435 1.1333Z" stroke="#CDA30D" strokeWidth="0.618864" />
            </g>
        </g>
        <g clipPath="url(#clip2_319_1376)">
            <g filter="url(#filter2_d_319_1376)">
                <path d="M45.8094 0.423828L48.4619 5.8965L54.487 6.72883L50.102 10.9432L51.1722 16.9301L45.8094 14.0614L40.446 16.9301L41.5169 10.9432L37.1318 6.72883L43.157 5.8965L45.8094 0.423828Z" fill="#FFE636" />
                <path d="M45.8094 1.1333L48.1834 6.03146L48.2556 6.18037L48.4195 6.20302L53.8124 6.948L49.8875 10.7201L49.7682 10.8348L49.7973 10.9977L50.7552 16.3561L45.9554 13.7885L45.8094 13.7105L45.6635 13.7885L40.863 16.3561L41.8215 10.9977L41.8507 10.8348L41.7313 10.7201L37.8064 6.948L43.1993 6.20302L43.3633 6.18037L43.4354 6.03146L45.8094 1.1333Z" stroke="#CDA30D" strokeWidth="0.618864" />
            </g>
        </g>
        <g clipPath="url(#clip3_319_1376)">
            <g filter="url(#filter3_d_319_1376)">
                <path d="M64.3754 0.423828L67.0278 5.8965L73.0529 6.72883L68.6679 10.9432L69.7381 16.9301L64.3754 14.0614L59.0119 16.9301L60.0828 10.9432L55.6978 6.72883L61.7229 5.8965L64.3754 0.423828Z" fill="#FFE636" />
                <path d="M64.3753 1.1333L66.7494 6.03146L66.8215 6.18037L66.9855 6.20302L72.3783 6.948L68.4535 10.7201L68.3341 10.8348L68.3633 10.9977L69.3212 16.3561L64.5213 13.7885L64.3754 13.7105L64.2294 13.7885L59.4289 16.3561L60.3874 10.9977L60.4166 10.8348L60.2972 10.7201L56.3724 6.948L61.7652 6.20302L61.9292 6.18037L62.0013 6.03146L64.3753 1.1333Z" stroke="#CDA30D" strokeWidth="0.618864" />
            </g>
        </g>
        <g clipPath="url(#clip4_319_1376)">
            <g filter="url(#filter4_d_319_1376)">
                <path d="M82.3224 0.423828L84.9748 5.8965L91 6.72883L86.6149 10.9432L87.6851 16.9301L82.3224 14.0614L76.9589 16.9301L78.0299 10.9432L73.6448 6.72883L79.6699 5.8965L82.3224 0.423828Z" fill="#FFE636" />
                <path d="M82.3224 1.1333L84.6964 6.03146L84.7685 6.18037L84.9325 6.20302L90.3254 6.948L86.4005 10.7201L86.2812 10.8348L86.3103 10.9977L87.2682 16.3561L82.4683 13.7885L82.3224 13.7105L82.1764 13.7885L77.3759 16.3561L78.3345 10.9977L78.3636 10.8348L78.2443 10.7201L74.3194 6.948L79.7123 6.20302L79.8762 6.18037L79.9484 6.03146L82.3224 1.1333Z" stroke="#CDA30D" strokeWidth="0.618864" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_319_1376" x="-0.618864" y="-0.195036" width="19.8307" height="18.9818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.618864" dy="0.618864" />
                <feGaussianBlur stdDeviation="0.618864" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_1376" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_1376" result="shape" />
            </filter>
            <filter id="filter1_d_319_1376" x="17.9471" y="-0.195036" width="19.8307" height="18.9818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.618864" dy="0.618864" />
                <feGaussianBlur stdDeviation="0.618864" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_1376" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_1376" result="shape" />
            </filter>
            <filter id="filter2_d_319_1376" x="36.513" y="-0.195036" width="19.8307" height="18.9818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.618864" dy="0.618864" />
                <feGaussianBlur stdDeviation="0.618864" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_1376" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_1376" result="shape" />
            </filter>
            <filter id="filter3_d_319_1376" x="55.0789" y="-0.195036" width="19.8307" height="18.9818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.618864" dy="0.618864" />
                <feGaussianBlur stdDeviation="0.618864" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_1376" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_1376" result="shape" />
            </filter>
            <filter id="filter4_d_319_1376" x="73.0259" y="-0.195036" width="19.8307" height="18.9818" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="0.618864" dy="0.618864" />
                <feGaussianBlur stdDeviation="0.618864" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_1376" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_1376" result="shape" />
            </filter>
            <clipPath id="clip0_319_1376">
                <rect width="17.3552" height="17.3552" fill="white" />
            </clipPath>
            <clipPath id="clip1_319_1376">
                <rect width="17.3552" height="17.3552" fill="white" transform="translate(18.5659)" />
            </clipPath>
            <clipPath id="clip2_319_1376">
                <rect width="17.3552" height="17.3552" fill="white" transform="translate(37.1318)" />
            </clipPath>
            <clipPath id="clip3_319_1376">
                <rect width="17.3552" height="17.3552" fill="white" transform="translate(55.6978)" />
            </clipPath>
            <clipPath id="clip4_319_1376">
                <rect width="17.3552" height="17.3552" fill="white" transform="translate(73.6448)" />
            </clipPath>
        </defs>
    </svg>

);

export default StarRatings;
