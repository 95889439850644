import { Data, get } from '../../networking';
import { CustomTypes, PrismicPaginatedResponse } from './types';
import { GetOptions, LinkField, OptionFilter, OptionFilterLink, PrismicOrderType } from './types/config.type';
import _ from 'lodash';
import { GetServerSidePropsContext } from 'next';

class Prismic {
    private getOrder: PrismicOrderType<any> | null;
    private getFilter: OptionFilter<string>[];
    private getFromTypeLink: OptionFilterLink | null;
    private getLinkedFields: LinkField[];
    private countNum: number;
    private pageNum: number;
    private getOptions: boolean;

    constructor() {
        this.getOrder = null;
        this.getFilter = [];
        this.getFromTypeLink = null;
        this.getLinkedFields = [];
        this.getOptions = false;
        this.countNum = 20;
        this.pageNum = 1;
    }

    async get(
        type: CustomTypes,
        context?: GetServerSidePropsContext,
        useCached = false,
        bustCache = false
    ): Promise<PrismicPaginatedResponse<any>> {
        try {
            if (this.getFilter.length) {
                this.getFilter.forEach((option) => {
                    if (option.type !== 'in' && Array.isArray(option.value)) {
                        throw new Error('Value arrays are only currently supported by "in" filterType');
                    }
                });
            }
            const getOptions: GetOptions<any> = {
                order: this.getOrder,
                filter: this.getFilter,
                fromTypeLink: this.getFromTypeLink,
                linkFields: this.getLinkedFields,
                count: this.countNum,
                page: this.pageNum
            };

            const result = await get(
                'prismic',
                {
                    getType: type,
                    getOptions: this.getOptions ? JSON.stringify(getOptions) : null,
                    useCached: useCached ? 'true' : 'false',
                    bustCache: bustCache ? 'true' : 'false'
                },
                context
            );

            return result;
        } catch (e) {
            throw new Error(e);
        }
    }

    /**
     *
     * @param token found as query param in preview link
     * @param documentId found as query param in preview link
     * @returns preview slug to redirect to
     */
    async getPreviewSlug(token: string, documentId: string): Promise<Data> {
        return await get('prismic/preview-slug', { token, documentId });
    }

    async getById(type: CustomTypes, id: string, context?: GetServerSidePropsContext) {
        this.getFilter = [{ by: 'id', type: 'equal', value: id }];
        this.hasOptions();
        return await this.get(type, context);
    }

    async getByUid(type: CustomTypes, uid: string, context?: GetServerSidePropsContext, useCached?: boolean, bustCache?: boolean) {
        this.getFilter = [{ by: 'uid', type: 'equal', value: uid }];
        this.hasOptions();
        return await this.get(type, context, useCached, bustCache);
    }

    async getRLink(slug: string): Promise<Data> {
        const result = await get('prismic/r-link', { slug: slug });
        return result;
    }

    private hasOptions() {
        this.getOptions = true;
    }

    count(count: number): Prismic {
        this.countNum = count;
        this.hasOptions();
        return this;
    }

    page(page: number): Prismic {
        this.pageNum = page;
        this.hasOptions();
        return this;
    }

    filterOptions(options: GetOptions<any>): Prismic {
        if (options.count) {
            this.countNum = options.count;
        }

        if (options.order) {
            this.getOrder = options.order;
        }

        if (options.filter && options.filter.length) {
            this.getFilter = [...this.getFilter, ...options.filter];
        }

        if (!_.isEmpty(options.fromTypeLink)) {
            this.getFromTypeLink = options.fromTypeLink;
        }

        if (options.linkFields && options.linkFields.length) {
            this.getLinkedFields = [...this.getLinkedFields, ...options.linkFields];
        }

        if (options.page) {
            this.pageNum = options.page;
        }
        this.hasOptions();
        return this;
    }

    filter(filter: OptionFilter<any> | OptionFilter<any>[]): Prismic {
        if (Array.isArray(filter)) {
            this.getFilter = [...this.getFilter, ...filter];
        } else {
            this.getFilter = [...this.getFilter, filter];
        }
        this.hasOptions();
        return this;
    }

    order(order: PrismicOrderType<any>): Prismic {
        this.getOrder = order;
        this.hasOptions();
        return this;
    }

    linkedFields(fields: LinkField[]): Prismic {
        this.getLinkedFields = fields;
        this.hasOptions();
        return this;
    }

    fromTypeLink(filterLink: OptionFilterLink): Prismic {
        this.getFromTypeLink = filterLink;
        this.hasOptions();
        return this;
    }
}

export default function() { return new Prismic(); }