import prismic from '../utils/prismic';
import { PrismicCategoryResponse, PrismicPaginatedResponse } from '../utils/prismic/types';

export const getFooterData = async () => {
    let result;
    await prismic().get('footer_section').then((response) => {
        const useCases = response.results[0].data.body.filter((item) => item.slice_type === 'use_cases')[0];
        const featuredArticles = response.results[0].data.body.filter((item) => item.slice_type === 'featured_articles')[0];
        result = {
            useCases,
            featuredArticles
        };
    });
    return result;
};

export const getCategories = async () => {
    const categories: PrismicPaginatedResponse<PrismicCategoryResponse> = await prismic().get('cat');
    return categories;
};