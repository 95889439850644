import { Box, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import NextImage from 'next/image';

import SunnysideGrid from '../../../components_sunnyside/grid';
import SunnysideButton from '../../../components_sunnyside/button';
import { diagnosticLink } from '../../../utils/diagnosticLinks';
import theme from '../../../theme';
import Team from '../../../../public/img/sunnyside/team.webp';
import { colors } from '../../../styles/_colors';

declare global {
    interface Window {
        Trustpilot:any;
    }
}

export default function HomeTeam(): JSX.Element {
    const ref = useRef(null);

    useEffect(() => {
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <SunnysideGrid
            style={{
                padding: 0,
                backgroundColor: colors.beige
            }}
            sx={{
                margin: '0 auto',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    margin: '0 auto 30px',
                    ['& div']: {
                        width: '100%',
                        height: '100%',
                        ['& img']: {
                            width: '100% !important',
                            height: '100% !important'
                        }
                    }
                }}
            >
                <NextImage
                    src={Team}
                    alt='Group photo of the Sunnyside team together.'
                    objectFit='fill'
                />
            </Box>
            <Box
                sx={{
                    padding: '0 27px',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1380px',
                    },
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        letterSpacing: '10%',
                        textAlign: 'center',
                        color: 'black',
                        textTransform: 'uppercase',
                        marginBottom: '8px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '12px',
                            lineHeight: '16px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '15px',
                            lineHeight: '22px',
                        },
                    }}
                >
                    The Team
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '46px',
                        lineHeight: '52px',
                        fontWeight: 800,
                        textAlign: 'center',
                        color: 'black',
                        marginBottom: '20px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '30px',
                            lineHeight: '32px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '46px',
                            lineHeight: '52px',
                        },
                    }}
                >
                    Built by people who care deeply.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        textAlign: 'center',
                        color: 'black',
                        width: '75%',
                        margin: '0 auto 50px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '13px',
                            lineHeight: '20px',
                            width: '100%',
                            margin: '0 0 50px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                            lineHeight: '26px',
                            width: '65%',
                        },
                    }}
                >
                    Founded in 2020 by Nick Allen and Ian Andersen (both children of alcoholic parents), our team of mindful drinkers, athletes, parents, and  health enthusiasts gets it. We’ve been where you are, and we’ve found a healthier relationship to alcohol by practicing what we preach. When you join Sunnyside, you’re joining an empathetic, understanding community of people like you!
                </Typography>
                <SunnysideButton
                    hovercolor={'#F2D529'}
                    url={diagnosticLink()}
                    textcolor={'#410C6A'}
                    fullWidth
                    height={'50px'}
                    sx={{
                        backgroundColor: '#FFE636',
                        fontWeight: 700,
                        borderRadius: '4px',
                        fontFamily: 'Poppins',
                        maxWidth: '411px',
                        margin: '0 auto 64px',
                        [theme.breakpoints.down(320)]: 
                    {
                        fontSize: '20px',
                        lineHeight: '36px',
                    },
                        [theme.breakpoints.up(320)]: 
                    {
                        fontSize: '20px',
                        lineHeight: '36px'
                    },
                    }}>
                    {'Join Sunnyside today'}
                </SunnysideButton>
            </Box>
        </SunnysideGrid>
    );
}