import React, { useEffect } from 'react';
import NextImage from 'next/image';
import Grid, { GridProps } from '@mui/material/Grid';
import { Typography, Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';

import HomeBannerHeroNew from '../../../../public/img/sunnyside/home_banner_new.webp';
import SunnysideButton from '../../../components_sunnyside/button';
import { colors } from '../../../styles/_colors';
import SunnysideGrid from '../../../components_sunnyside/grid';
import { Media, MediaContextProvider } from '../../../styles/media';
import { diagnosticLink } from '../../../utils/diagnosticLinks';
import { ampLogEvent } from '../../../lib/amplitude';
import { useCookies } from 'react-cookie';
import StarRatings from '../../../../public/icons/sunnyside/ratings';

export default function HomeBanner({ version }: { version?: number }): JSX.Element {
    const [cookies] = useCookies();
    const theme = useTheme();

    useEffect(() => {
        const attributes: any = {};
        if (version) {
            attributes.pageVersion = `homepage ${version}`;
        }
        
        ampLogEvent(
            'signup: landed on homepage',
            attributes,
            cookies
        );
    }, []);

    const ContentGrid = styled(Grid)<GridProps>(({ theme }) => ({
        background: colors.beige,
        position: 'relative',
        zIndex: 2,
        [theme.breakpoints.down(400)]: {
            backgroundSize: '150%',
        },
        [theme.breakpoints.up(400)]: {
            backgroundSize: '200%',
        },
        [theme.breakpoints.up('md')]: {
            backgroundSize: '200%',
        },
    }));
    const ContentBox = styled(Box)<BoxProps>(() => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '50%',
        marginTop: 'auto',
    }));
    const Title = styled('span')(({ theme }) => ({
        fontFamily: 'Poppins',
        fontWeight: 800,
        fontSize: '76px',
        lineHeight: '76px',
        letterSpacing: '-2%',
        color: '#125782',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '46px',
            lineHeight: '42px',
            marginBottom: 20,
            textAlign: 'center',
        },
    }));
    const Subtitle = styled('p')(() => ({
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '22px',
        lineHeight: '32px',
        fontWeight: 600,
        width: '90%'
    }));
    const ImageBox = styled(Box)<BoxProps>(() => ({
        display: 'flex',
        maxWidth: '48%',
        backgroundColor: colors.beige,
        margin: 'auto 0 0 auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    }));

    function MobileView() {
        return (
            <>
                <ContentGrid container item xs={6} justifyContent="center">
                    <Title>
                        The #1 alcohol moderation platform.
                    </Title>
                    <Typography
                        style={{
                            textAlign: 'center',
                            color: 'black',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: 600,
                            marginBottom: '16px',
                            padding: '0 30px'
                        }}
                    >
                        An evidence-based system to help you make changes to your drinking that last, whether you want to drink less, or eventually quit.                     
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '30px'
                        }}
                    >
                        <StarRatings />
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                fontSize: '12px',
                                lineHeight: '24px',
                                color: '#BA9613',
                                textTransform: 'uppercase',
                                marginLeft: '6px'
                            }}
                        >
                            4.8 rating from 250k+ members
                        </Typography>
                    </Box>
                    <SunnysideButton 
                        url={diagnosticLink()}
                        textcolor={'#410C6A'}
                        fullWidth
                        margin={'0 30px 16px'}
                        height={'50px'}
                        sx={{
                            backgroundColor: '#FFE636',
                            fontWeight: 700,
                            borderRadius: '4px',
                            fontFamily: 'Poppins',
                            [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                            [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                        }}
                    >
                        {'Take the 3-minute quiz'}
                    </SunnysideButton>
                    <Typography
                        style={{
                            color: 'black',
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '16px',
                            marginBottom: '16px'
                        }}>
                            Start for free. Cancel any time.
                    </Typography>
                </ContentGrid>
                <ImageBox>
                    <NextImage
                        src={HomeBannerHeroNew}
                        alt='Screenshots of the Sunnyside app. On the left, a view of the progress screen. On the right, a view of an active challenge.'
                        width={614}
                        height={659}
                        priority
                    />
                </ImageBox>
            </>
        );
    }

    function DesktopView() {
        return (
            <Box
                style={{
                    width: '100%',
                    backgroundColor: colors.beige
                }}
            >
                <SunnysideGrid
                    background={colors.beige}
                    style={{
                        padding: '50px 100px 0',
                    }}
                    sx={{
                        margin: '0 auto',
                        [theme.breakpoints.up('xl')]: {
                            maxWidth: '1380px',
                        },
                    }}
                >
                    <ContentBox> 
                        <Title>
                            The #1 alcohol moderation platform.
                        </Title>
                        <Subtitle>
                            An evidence-based system to help you make changes to your drinking that last, whether you want to drink less, or eventually quit.                   
                        </Subtitle>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '30px'
                            }}
                        >
                            <StarRatings />
                            <Typography
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    lineHeight: '24px',
                                    color: '#BA9613',
                                    textTransform: 'uppercase',
                                    marginLeft: '6px'
                                }}
                            >
                                4.8 rating from 250k+ members
                            </Typography>
                        </Box>
                        <SunnysideButton 
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            height={'50px'}
                            hovercolor={'#F2D529'}
                            fullWidth
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                maxWidth: '411px',
                                [theme.breakpoints.down(320)]: 
                                    { 
                                        fontSize: '20px',
                                        lineHeight: '36px',
                                    },
                                [theme.breakpoints.up(320)]: 
                                    {
                                        fontSize: '20px',
                                        lineHeight: '36px'
                                    },
                            }}>
                            {'Take the 3-minute quiz'}
                        </SunnysideButton>
                        <Typography
                            style={{
                                color: 'black',
                                fontFamily: 'Poppins',
                                fontWeight: 700,
                                fontSize: '12px',
                                lineHeight: '16px',
                                textAlign: 'center',
                                width: '411px',
                                marginTop: '16px',
                                marginBottom: '80px'
                            }}
                        >
                            Start for free. Cancel any time.
                        </Typography>
                    </ContentBox>
                    <ImageBox>
                        <NextImage
                            src={HomeBannerHeroNew}
                            alt='Screenshots of the Sunnyside app. On the left, a view of the progress screen. On the right, a view of an active challenge.'
                            width={614}
                            height={659}
                            priority
                        />
                    </ImageBox>
                </SunnysideGrid>
            </Box>
        );
    }

    return (
        <MediaContextProvider>
            <Media lessThan='md'>
                <MobileView />
            </Media>
            <Media greaterThanOrEqual='md'>
                <DesktopView />
            </Media>
        </MediaContextProvider>
    );
}