import { styled } from '@mui/material/styles';


export interface SunnysideMainProps {
    width?: string,
    children: any,
}

export default function SunnysideMain(props: SunnysideMainProps): JSX.Element {
    const { width, children } = props;
    const Main = styled('main')(() => ({
        width: width || '100%',
    }));
    return (
        <Main>
            {children}
        </Main>
    );
}