
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export default function Divider(props: BoxProps) {
    const Divider = styled(Box)<BoxProps>(({ theme }) => ({
        background: '#E3E3E3',
        width: '100%',
        height: '1px'
    }));

    return (
        <Divider {...props}/>
    );
}