import { Box, Grid, SvgIcon, Typography, styled } from '@mui/material';

import SunnysideGrid from '../../../components_sunnyside/grid';
import Heart from '../../../../public/icons/dry-january/heart.svg';
import Secure from '../../../../public/icons/dry-january/secure.svg';
import Cocktail from '../../../../public/icons/dry-january/cocktail.svg';
import Bag from '../../../../public/icons/dry-january/bag.svg';
import Toggle from '../../../../public/icons/dry-january/toggle.svg';
import Shield from '../../../../public/icons/dry-january/shield.svg';
import theme from '../../../theme';

export default function HomePromise(): JSX.Element {
    const PromiseBox = styled(SunnysideGrid)(({ theme }) => ({
        background: '#B05DF1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 27px',
        margin: '0 auto',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1380px',
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        '& .grid': {
            placeContent: 'space-evenly',
            margin: 0,
            rowGap: '50px',
            columnGap: '30px',
            marginBottom: '50px',
            [theme.breakpoints.down('md')]: {
                width: '80%',
            },
            '& .promise': {
                maxWidth: '350px',
                width: '30%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.down('md')]: {
                    maxWidth: '350px',
                    width: '100%'
                },
                '& .iconBox': {
                    borderRadius: '50%',
                    marginBottom: '20px',
                    width: '50px',
                    height: '50px',
                    '& .icon': {
                        width: '100%',
                        height: '100%'
                    }
                },
                '& .header': {
                    fontSize: '22px',
                    lineHeight: '19px',
                    fontWeight: 700,
                    fontFamily: 'Poppins',
                    color: 'white',
                    textAlign: 'center',
                    marginBottom: '20px',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '18px',
                        lineHeight: '18px',
                    },
                },
                '& .details': {
                    fontSize: '16px',
                    lineHeight: '21px',
                    color: 'white',
                    fontFamily: 'Poppins',
                    fontWeight: 500,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                        fontSize: '14px',
                        lineHeight: '20px',
                    },
                }
            }
        }
    }));
    return (
        <Box
            style={{
                width: '100%',
                backgroundColor: '#B05DF1'
            }}
        >
            <PromiseBox>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        letterSpacing: '10%',
                        textAlign: 'center',
                        color: 'white',
                        textTransform: 'uppercase',
                        marginBottom: '8px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '12px',
                            lineHeight: '16px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '15px',
                            lineHeight: '22px',
                        },
                    }}
                >
                We mean it
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '46px',
                        lineHeight: '52px',
                        fontWeight: 800,
                        textAlign: 'center',
                        color: 'white',
                        marginBottom: '20px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '30px',
                            lineHeight: '32px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '46px',
                            lineHeight: '52px',
                        },
                    }}
                >
                Our promise to you.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        textAlign: 'center',
                        color: 'white',
                        width: '75%',
                        margin: '0 auto 50px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '13px',
                            lineHeight: '20px',
                            width: '100%',
                            margin: '0 0 50px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                            lineHeight: '26px',
                        },
                    }}
                >
                We know this is a deeply personal journey for you, as it was for us. We follow a strict code of conduct and promise to always put your health and wellness above all else.
                </Typography>
                <Grid style={{ margin: 0 }} container className={'grid'} columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 5 }}>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'0 0 43 42'}><Heart /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>No shame or guilt ever</Typography>
                        <Typography variant={'body1'} className={'details'}>Mindful drinking is about celebrating our wins, not making you feel bad.</Typography>
                    </Box>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'4.5 4.5 43 42'}><Secure /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>Always private and secure</Typography>
                        <Typography variant={'body1'} className={'details'}>This is a personal, private journey for you. We make privacy a top priority.</Typography>
                    </Box>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'11 22 46 22'}><Cocktail /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>No need to quit drinking</Typography>
                        <Typography variant={'body1'} className={'details'}>98% of Sunnyside members still drink. We&apos;re all about moderation.</Typography>
                    </Box>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'3.75 2.75 46 48'}><Bag /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>30-day money-back guarantee</Typography>
                        <Typography variant={'body1'} className={'details'}>If you give it a fair shot and aren&apos;t happy after 30 days, just let us know!</Typography>
                    </Box>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'1.5 2 45.5 45'}><Toggle /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>You&apos;re always in control</Typography>
                        <Typography variant={'body1'} className={'details'}>You can adjust your goals and weekly plans at any time. It&apos;s up to you!</Typography>
                    </Box>
                    <Box className={'promise'}>
                        <Box className={'iconBox'}><SvgIcon className={'icon'} viewBox={'0 0 36 44'}><Shield /></SvgIcon></Box>
                        <Typography variant={'h2'} className={'header'}>15-day risk-free trial</Typography>
                        <Typography variant={'body1'} className={'details'}>Get access to everything for two weeks to make sure it&apos;s right for you.</Typography>
                    </Box>
                </Grid>
            </PromiseBox>
        </Box>
    );
}