import { Typography, Grid, GridProps, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import SunnysideButton from '../../../components_sunnyside/button';
import { colors } from '../../../styles/_colors';
import { PrismicHomePageData } from '../../../utils/prismic/types/pages/home.type';

interface HomeAnnouncementProps {
    data: PrismicHomePageData;
}
export default function HomeAnnouncement(props: HomeAnnouncementProps): JSX.Element {
    const { data } = props;
    const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.25rem',
        background: colors.pine,
        '& a': {
            [theme.breakpoints.down(375)]: {
                fontSize: '0.875rem',
                lineHeight: '1.0625rem',
                padding: '0.25rem 0.3rem',
                width: 'fit-content',
                height: 'fit-content'
            },
            [theme.breakpoints.up(375)]: {
                fontSize: '0.875rem',
                lineHeight: '1.0625rem',
                padding: '0.25rem 0.5rem',
                width: 'fit-content',
                height: 'fit-content'
            },
            [theme.breakpoints.up(500)]: {
                width: 'fit-content',
                height: '2rem'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '1.0625rem',
                width: '8rem',
                height: '2rem'
            },
        }
    }));
    const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
        fontFamily: 'Recoleta Medium',
        fontWeight: 400,
        fontStyle: 'normal',
        color: colors.cloud,
        textAlign: 'center',
        letterSpacing: '-0.02em',
        [theme.breakpoints.down(375)]: {
            fontSize: '0.875rem',
            lineHeight: '1.1875rem',
            paddingRight: '0.5rem',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up(375)]: {
            fontSize: '0.875rem',
            lineHeight: '1.1875rem',
            paddingRight: '0.5rem',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.875rem',
            lineHeight: '1.1875rem',
            paddingRight: '0.5rem'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
            lineHeight: '1.6875rem',
            paddingRight: '0.75rem',
        },
    }));
    return (
        <StyledGrid>
            <Text>
                {data.data.homepage_announcement_bar_text}
            </Text>
            <SunnysideButton 
                bgcolor={colors.sunny} 
                textcolor={colors.pine}
                url={data.data.homepage_announcement_bar_button_link}
                hovercolor={colors.sunny}>
                {data.data.homepage_announcement_bar_button_text}
            </SunnysideButton>
        </StyledGrid>
    );
}