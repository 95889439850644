import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Typography, styled } from '@mui/material';
import SunnysideLink from '../link';
import Divider from '../divider';
import { diagnosticLink } from '../../utils/diagnosticLinks';
import { PrismicFooterGenericItem, PrismicFooterHeader } from '../../utils/prismic/types/prismic-slice.type';
import SunnysidePineLogo from '../../../public/icons/sunnyside/sunnyside-logo-pine-2';

export default function MobileFooter(props: any): JSX.Element {
    const { useCases } = props;
    const useCasesItems = useCases?.items as PrismicFooterGenericItem[];
    const useCasesPrimary = useCases?.primary as PrismicFooterHeader;

    const LinkBox = styled(Box)<BoxProps>(() => ({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '2rem',
        borderTop: '1px solid #E2E2E2',
        '& a': {
            fontSize: '12px',
            lineHeight: '16px',
        }
    }));

    const DescriptorBox = styled(Box)<BoxProps>(() => ({
        flex: '3',
        margin: '50px 0',
        '& a': {
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '22px',
            color: '#575757'
        }
    }));

    const FooterLinkBox = styled(Box)<BoxProps>(() => ({
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        justifyContent: 'space-between',
        '& a': {
            fontSize: '0.875rem !important',
            lineHeight: '2.25rem !important',
            padding: '0rem !important'
        }
    }));

    return (
        <>
            <SunnysidePineLogo width='80' height='26' />
            <DescriptorBox>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '24px',
                        color: '#000000',
                        textTransform: 'uppercase'
                    }}
                >
                    About Sunnyside
                </Typography>
                <Divider style={{ margin: '16px 0 20px' }} />
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontSize: '13px',
                        lineHeight: '22px',
                        color: '#575757'
                    }}
                >
                    Sunnyside is a system for creating a
                    more mindful approach to drinking
                    to help you reach your goals. Our
                    members are seeing major benefits,
                    including a reduction in weekly
                    drinks, improved sleep, a healthier
                    diet, money saved, and an overall
                    improved sense of well-being.
                    <br /><br />
                    Sunnyside is not designed to
                    treat alcohol use disorder (AUD). For
                    resources related to AUD, including
                    how to get support, please visit the&nbsp;
                    <SunnysideLink
                        href='https://www.nih.gov'
                        fontVariant='footer_text'
                        fontFamily={'Poppins'}
                        color={'#575757'}
                        underline
                        external
                    >
                        NIH
                    </SunnysideLink>
                    &nbsp;website. We&apos;ve also partnered with&nbsp;
                    <SunnysideLink
                        href='https://moderation.org'
                        fontVariant='footer_text'
                        fontFamily={'Poppins'}
                        color={'#575757'}
                        underline
                        external
                    >
                        Moderation Management
                    </SunnysideLink>
                    , a non-profit dedicated to reducing the
                    harm caused by the misuse of alcohol.
                </Typography>
            </DescriptorBox>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '30px',
                    marginBottom: '50px'
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1'
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '24px',
                            color: '#000000',
                            textTransform: 'uppercase'
                        }}
                    >
                        Explore
                    </Typography>
                    <Divider style={{ margin: '16px 0 20px' }} />
                    <SunnysideLink padding={'0px'} href="/">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Home
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href="/blog">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Blog
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href="/reviews">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Reviews
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href="/about">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            About
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} external href="https://help.sunnyside.co">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Help &amp; Support
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} external href="https://get.sunnyside.co/organizations">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            For Organizations
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href="/partners">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Partner with us
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href="/affiliates">
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Become an affiliate
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href={diagnosticLink()}>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Sign Up
                        </Typography>
                    </SunnysideLink>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '1',
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '24px',
                            color: '#000000',
                            textTransform: 'uppercase'
                        }}
                    >
                        Get in Touch
                    </Typography>
                    <Divider style={{ margin: '16px 0 20px' }} />
                    <SunnysideLink padding={'0px'} external href='https://www.instagram.com/joinsunnyside/'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Instagram
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} external href='https://twitter.com/joinsunnyside'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Twitter
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} external href='https://www.linkedin.com/company/join-sunnyside/'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            LinkedIn
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} external href='https://www.facebook.com/joinsunnyside'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Facebook
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href='mailto:hello@sunnyside.co'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Contact Us
                        </Typography>
                    </SunnysideLink>
                    <SunnysideLink padding={'0px'} href='mailto:press@sunnyside.co'>
                        <Typography
                            style={{
                                fontFamily: 'Poppins',
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '32px',
                                color: '#575757'
                            }}
                        >
                            Contact Press
                        </Typography>
                    </SunnysideLink>
                </Box>
            </Box>
            {useCases &&
                <FooterLinkBox>
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            fontSize: '12px',
                            lineHeight: '24px',
                            color: '#000000',
                            textTransform: 'uppercase'
                        }}
                    >
                        {useCasesPrimary.header_title[0].text}
                    </Typography>
                    <Divider style={{ margin: '16px 0 20px' }} />
                    <Box
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            marginBottom: '50px'
                        }}
                    >
                        {[0, 1].map((column) => (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                key={column}
                            >
                                {useCasesItems.slice(column * 9, column * 9 + 9).map((useCase, row) => (
                                    <SunnysideLink
                                        href={useCase.url.url}
                                        key={row}
                                    >
                                        <Typography
                                            style={{
                                                fontFamily: 'Poppins',
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                lineHeight: '32px',
                                                color: '#575757'
                                            }}
                                        >
                                            {useCase.title}
                                        </Typography>
                                    </SunnysideLink>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </FooterLinkBox>
            }
            <LinkBox>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#919191',
                        marginBottom: '8px'
                    }}
                >
                    Copyright {new Date().getFullYear()} Cutback Coach, Inc. All rights reserved.
                </Typography>
                <Typography
                    style={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#919191',
                        textAlign: 'center'
                    }}
                >
                    <SunnysideLink fontFamily={'Poppins'} href='/privacy' color={'#919191'}>Privacy Policy</SunnysideLink>
                    &nbsp;|&nbsp;
                    <SunnysideLink fontFamily={'Poppins'} href='/terms' color={'#919191'}>Terms &amp; Conditions</SunnysideLink>
                    &nbsp;|&nbsp;
                    <SunnysideLink fontFamily={'Poppins'} href='/cookies' color={'#919191'}>Cookie Policy</SunnysideLink>
                </Typography>
            </LinkBox>
        </>
    );
}