import { Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NextImage from 'next/image';

import OprahDailyIcon from '../../../../public/img/sunnyside/oprah_daily_icon.webp';
import ForbesIcon from '../../../../public/img/sunnyside/forbes_black_icon.webp';
import USATodayIcon from '../../../../public/img/sunnyside/usa_today_icon.webp';
import NewYorkTimesIcon from '../../../../public/img/sunnyside/new_york_times_icon.webp';

import { Media, MediaContextProvider } from '../../../styles/media';
import theme from '../../../theme';

export default function FeaturedIn(): JSX.Element {
    const LogoBox = styled(Box)<BoxProps>(({ theme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 40px',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: '0',
        },
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0rem 0rem 0rem 1.5rem'
        },
    }));
    const Column = styled(Box)<BoxProps>(({ theme }) => ({
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            margin: '0',
        },
        [theme.breakpoints.up('lg')]: {
            width: 'fit-content',
        },
    }));
    return (
        <Box
            style={{
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    padding: '30px 0',
                    margin: '0 auto',
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1380px',
                    },
                }}
            >
                <MediaContextProvider>
                    <Media lessThan='md'>
                        <Typography 
                            style={{
                                fontFamily: 'Poppins',
                                fontSize: '9px',
                                lineHeight: '18px',
                                fontWeight: 600,
                                color: '#A2A2A2',
                                textTransform: 'uppercase',
                                width: 'fit-content',
                                margin: '0 auto 10px'
                            }}
                        >
                                Proudly seen in
                        </Typography>
                        <LogoBox>
                            <Column>
                                <NextImage src={NewYorkTimesIcon}
                                    alt='New York Times logo' 
                                    width={139}
                                    height={21}
                                    objectFit='cover'
                                    objectPosition='center'
                                />
                            </Column>
                            <Column style={{ marginLeft: '30px' }}>
                                <NextImage src={OprahDailyIcon}
                                    alt='Opah Daily logo' 
                                    width={99}
                                    height={36}
                                    objectFit='cover'
                                    objectPosition='center'
                                />
                            </Column>
                        </LogoBox>
                        <LogoBox>
                            <Column>
                                <NextImage src={ForbesIcon}
                                    alt='Forbes logo' 
                                    width={91}
                                    height={24}
                                    objectFit='cover'
                                    objectPosition='center'
                                />
                            </Column>
                            <Column style={{ marginLeft: '30px', alignSelf: 'flex-end' }}>
                                <NextImage src={USATodayIcon}
                                    alt='USA Today logo' 
                                    width={118}
                                    height={17}
                                    objectFit='cover'
                                    objectPosition='center'
                                />
                            </Column>
                        </LogoBox>
                    </Media>
                    <Media greaterThanOrEqual='md'>
                        <Box
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography 
                                style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    lineHeight: '28px',
                                    fontWeight: 600,
                                    color: '#A2A2A2',
                                    textTransform: 'uppercase',
                                    width: 'fit-content',
                                    margin: '0 auto'
                                }}
                            >
                                    Proudly seen in
                            </Typography>
                            <Column>
                                <NextImage src={NewYorkTimesIcon}
                                    alt='New York Times logo' 
                                    width={228}
                                    height={34}
                                    objectFit='cover'
                                    objectPosition='center'
                                    priority
                                />
                            </Column>
                            <Column>
                                <NextImage src={OprahDailyIcon}
                                    alt='Opah Daily logo' 
                                    width={162}
                                    height={59}
                                    objectFit='cover'
                                    objectPosition='center'
                                    priority
                                />
                            </Column>
                            <Column>
                                <NextImage src={ForbesIcon}
                                    alt='Forbes logo' 
                                    width={150}
                                    height={40}
                                    objectFit='cover'
                                    objectPosition='center'
                                    priority
                                />
                            </Column>
                            <Column>
                                <NextImage src={USATodayIcon}
                                    alt='USA Today logo' 
                                    width={194}
                                    height={28}
                                    objectFit='cover'
                                    objectPosition='center'
                                    priority
                                />
                            </Column>
                        </Box>
                    </Media>
                </MediaContextProvider>
            </Box>
        </Box>
    );
}