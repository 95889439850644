import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import NextImage from 'next/image';

import DrinksPie from '../../../../public/img/sunnyside/drinks-pie.webp';
import DrinksBar from '../../../../public/img/sunnyside/drinks-bar.webp';
import SunnysideGrid from '../../../components_sunnyside/grid';
import SunnysideButton from '../../../components_sunnyside/button';
import theme from '../../../theme';
import { diagnosticLink } from '../../../utils/diagnosticLinks';

export default function Home(): JSX.Element {
    return (
        <Box
            style={{
                width: '100%',
                backgroundColor: '#125782'
            }}
        >
            <SunnysideGrid
                style={{ padding: 0 }}
                sx={{
                    margin: '0 auto',
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1380px',
                    },
                }}
            >
                <Box
                    style={{
                        width: '100%',
                        backgroundColor: '#125782',
                        padding: '50px 25px',
                        flexDirection: 'column',
                        display: 'flex',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            letterSpacing: '10%',
                            textAlign: 'center',
                            color: 'white',
                            textTransform: 'uppercase',
                            marginBottom: '8px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '12px',
                                lineHeight: '16px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '15px',
                                lineHeight: '22px',
                            },
                        }}
                    >
                    The Science
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '46px',
                            lineHeight: '52px',
                            fontWeight: 800,
                            textAlign: 'center',
                            color: 'white',
                            marginBottom: '20px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '30px',
                                lineHeight: '32px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '46px',
                                lineHeight: '52px',
                            },
                        }}
                    >
                    Powered by science, backed by experts.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            textAlign: 'center',
                            color: 'white',
                            width: '75%',
                            margin: '0 auto 50px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '13px',
                                lineHeight: '20px',
                                width: '100%',
                                margin: '0 0 50px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '18px',
                                lineHeight: '26px',
                            },
                        }}
                    >
                    Developed with our medical experts, Sunnyside has been verified in a 3rd-party study to reduce weekly alcohol consumption by 30% on average after 90 days.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '0 200px',
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                padding: '0 20px'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                [theme.breakpoints.down('md')]: {
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    margin: '0 auto 40px',
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '35%',
                                    display: 'flex',
                                    height: 'fit-content',
                                    [theme.breakpoints.down('md')]: {
                                        alignSelf: 'flex-end',
                                    }
                                }}
                            >
                                <NextImage
                                    src={DrinksPie}
                                    alt='Pie chart: 96% of people drink substantially less after 90 days.'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '180px',
                                    marginLeft: '10px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '62px',
                                        lineHeight: '62px',
                                        fontWeight: 800,
                                        letterSpacing: '-2%',
                                        color: '#FFE635',
                                        marginBottom: '8px'
                                    }}
                                >
                            96%
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        fontWeight: 700,
                                        color: 'white',
                                    }}
                                >
                            drink substantially less after 90 days.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '50px',
                                justifyContent: 'center',
                                [theme.breakpoints.down('md')]: {
                                    alignItems: 'center',
                                    width: 'fit-content',
                                    margin: '0 auto 40px',
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    maxWidth: '35%',
                                    [theme.breakpoints.down('md')]: {
                                        alignSelf: 'flex-end',
                                    }
                                }}
                            >
                                <NextImage
                                    src={DrinksBar}
                                    alt='Bar chart: 30% average reduction in weekly drinks.'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '180px',
                                    marginLeft: '10px'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '62px',
                                        lineHeight: '62px',
                                        fontWeight: 800,
                                        letterSpacing: '-2%',
                                        color: '#FFE635',
                                        marginBottom: '8px'
                                    }}
                                >
                            30%
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '18px',
                                        lineHeight: '18px',
                                        fontWeight: 700,
                                        color: 'white',
                                    }}
                                >
                            average reduction in weekly drinks.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <SunnysideButton
                        url={diagnosticLink()}
                        textcolor={'#410C6A'}
                        fullWidth
                        height={'50px'}
                        margin={'0 auto'}
                        hovercolor={'#F2D529'}
                        sx={{
                            backgroundColor: '#FFE636',
                            fontWeight: 700,
                            borderRadius: '4px',
                            fontFamily: 'Poppins',
                            maxWidth: '411px',
                            [theme.breakpoints.down(320)]: 
                            {
                                fontSize: '20px',
                                lineHeight: '36px',
                            },
                            [theme.breakpoints.up(320)]: 
                            {
                                fontSize: '20px',
                                lineHeight: '36px'
                            },
                        }}>
                        {'Start drinking less'}
                    </SunnysideButton>
                </Box>
            </SunnysideGrid>
        </Box>
    );
}