import Box from '@mui/material/Box';
import { Grid, List } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import NextImage from 'next/image';

import SunnysideGrid from '../../../components_sunnyside/grid';
import { colors } from '../../../styles/_colors';
import MentalHealthHeartIcon from '../../../../public/img/sunnyside/mental-health-heart-icon.webp';
import PhysicalHealthIcon from '../../../../public/img/sunnyside/physical-health-icon.webp';
import DrinkLessIcon from '../../../../public/img/sunnyside/drink-less-icon.webp';
import SunnysideButton from '../../../components_sunnyside/button';
import theme from '../../../theme';
import { diagnosticLink } from '../../../utils/diagnosticLinks';

export default function HomeBenefits(): JSX.Element {
    const ResultsBox = styled(SunnysideGrid)(({ theme }) => ({
        backgroundColor: colors.beige,
        marginBottom: '50px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1380px',
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: '10px',
        },
        '& .sectionTitle': {
            fontSize: '46px',
            lineHeight: '46px',
            color: 'white',
            fontWeight: 800,
            fontFamily: 'Poppins',
            marginBottom: '25px',
            textAlign: 'center',
            width: '100%',
            [theme.breakpoints.down('lg')]: {
                fontSize: '42px',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '36px',
                lineHeight: '38px',
                width: '80%',
                marginBottom: '20px',
            },
        },
        '& .grid': {
            margin: '0 auto 30px',
            placeContent: 'space-evenly',
            width: 'fit-content',
            [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
            },
            '& .section': {
                borderRadius: '5px',
                padding: '30px 20px',
                width: '30%',
                maxWidth: '366px',
                height: '366px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                [theme.breakpoints.down('lg')]: {
                    width: 'unset',
                    marginBottom: '20px',
                },
                [theme.breakpoints.down('md')]: {
                    '&:nth-of-type(3)': {
                        marginBottom: 'unset',
                    }
                },
                '&:nth-of-type(2) .title': {
                    color: '#B05DF1',
                },
                '&:nth-of-type(3) .title': {
                    color: '#5CD28B',
                },
                '& .sectionTop': {
                    alignItems: 'center',
                    '& .illustration': {
                        margin: '0 auto 12px',
                        width: 'fit-content',
                    },
                    '& .title': {
                        fontSize: '20px',
                        lineHeight: '24px',
                        fontWeight: 700,
                        fontFamily: 'Poppins',
                        color: '#333',
                        marginBottom: '5px',
                        textAlign: 'center'
                    },
                },
                '& .divider': {
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#F0F0F0',
                    margin: '14px 0'
                },
                '& .list': {
                    listStyle: 'disc',
                    marginLeft: '22px',
                    padding: '0 10px',
                    '& .item': {
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '28px',
                        color: 'black',
                    }
                }
            }
        }
    }));
    
    return (
        <Box
            style={{
                width: '100%',
                backgroundColor: colors.beige
            }}
        >
            <ResultsBox style={{ padding: '50px 27px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        letterSpacing: '10%',
                        textAlign: 'center',
                        color: 'black',
                        textTransform: 'uppercase',
                        marginBottom: '8px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '12px',
                            lineHeight: '16px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '15px',
                            lineHeight: '22px',
                        },
                    }}
                >
                The Benefits
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '46px',
                        lineHeight: '52px',
                        fontWeight: 800,
                        textAlign: 'center',
                        color: 'black',
                        marginBottom: '20px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '30px',
                            lineHeight: '32px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '46px',
                            lineHeight: '52px',
                        },
                    }}
                >
                The healthiest, happiest version of you.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        textAlign: 'center',
                        color: 'black',
                        width: '75%',
                        margin: '0 auto 50px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '13px',
                            lineHeight: '20px',
                            width: '100%',
                            margin: '0 0 50px'
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                            lineHeight: '26px',
                        },
                    }}
                >
                We asked Sunnyside members how they’re feeling after using Sunnyside for 90 days. The results are incredible, and you can enjoy them too when you join:
                </Typography>
                <Grid container className={'grid'} columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 5 }}>
                    <Box className={'section'}>
                        <Box className={'sectionTop'}>
                            <Box className={'illustration'}>
                                <NextImage src={DrinkLessIcon}
                                    alt='Icon of two glasses clinking' 
                                    width={46}
                                    height={67}
                                />
                            </Box>
                            <Typography className={'title'}>You’ll drink less with no pressure to quit:</Typography>
                        </Box>
                        <Box className={'divider'} />
                        <List className={'list'}>
                            <li className={'item'}>96% drink less</li>
                            <li className={'item'}>81% better manage cravings</li>
                            <li className={'item'}>90% binge drink less</li>
                            <li className={'item'}>87% have fewer hangovers</li>
                        </List>
                    </Box>
                    <Box className={'section'}>
                        <Box className={'sectionTop'}>
                            <Box className={'illustration'}>
                                <NextImage src={MentalHealthHeartIcon}
                                    alt='Icon of a heart hugging itself' 
                                    width={77}
                                    height={67}
                                />
                            </Box>
                            <Typography className={'title'} style={{ padding: '0 30px' }}>Your mental health will improve:</Typography>
                        </Box>
                        <Box className={'divider'} />
                        <List className={'list'}>
                            <li className={'item'}>80% feel accomplished</li>
                            <li className={'item'}>75% improve mental health</li>
                            <li className={'item'}>67% think more clearly</li>
                            <li className={'item'}>66% feel less shame</li>
                        </List>
                    </Box>
                    <Box className={'section'}>
                        <Box className={'sectionTop'}>
                            <Box className={'illustration'}>
                                <NextImage src={PhysicalHealthIcon}
                                    alt='Icon of a trophy' 
                                    width={59}
                                    height={67}
                                />
                            </Box>
                            <Typography className={'title'}>Your physical health and focus will peak:</Typography>
                        </Box>
                        <Box className={'divider'} />
                        <List className={'list'}>
                            <li className={'item'}>73% improve sleep quality</li>
                            <li className={'item'}>54% improve productivity</li>
                            <li className={'item'}>46% improve fitness</li>
                            <li className={'item'}>29% lose weight</li>
                        </List>
                    </Box>
                </Grid>
                <SunnysideButton
                    url={diagnosticLink()}
                    textcolor={'#410C6A'}
                    fullWidth
                    height={'50px'}
                    margin={'0 auto'}
                    hovercolor={'#F2D529'}
                    sx={{
                        backgroundColor: '#FFE636',
                        fontWeight: 700,
                        borderRadius: '4px',
                        fontFamily: 'Poppins',
                        maxWidth: '411px',
                        [theme.breakpoints.down(320)]: 
                        {
                            fontSize: '20px',
                            lineHeight: '36px',
                        },
                        [theme.breakpoints.up(320)]: 
                        {
                            fontSize: '20px',
                            lineHeight: '36px'
                        },
                    }}>
                    {'Start drinking less'}
                </SunnysideButton>
            </ResultsBox>
        </Box>
    );
}