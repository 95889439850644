import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';

import { colors } from '../../styles/_colors';

export interface SunnysideWrapperProps extends GridProps {
    background?: any,
    width?: any,
    children: any,
}

export default function SunnysideWrapper(props: SunnysideWrapperProps): JSX.Element {
    const { children, background, width } = props;
    const ParentGrid = styled(Grid)<GridProps>(({ theme }) => ({
        background: background || colors.cloud,
        width: width,
    }));
    return (
        <ParentGrid container columns={{ xs: 6, sm: 12 }} className='sunnyside'>
            {children}
        </ParentGrid>
    );
}