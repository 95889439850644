import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NextImage from 'next/image';

import SunnysideButton from '../../../components_sunnyside/button';
import SunnysideGrid from '../../../components_sunnyside/grid';
import { diagnosticLink } from '../../../utils/diagnosticLinks';

import Testimonial1 from '../../../../public/img/dry-january/testimonial1.webp';
import Testimonial2 from '../../../../public/img/dry-january/testimonial2.webp';
import Testimonial3 from '../../../../public/img/dry-january/testimonial3.webp';
import Testimonial4 from '../../../../public/img/dry-january/testimonial4.webp';
import Testimonial5 from '../../../../public/img/dry-january/testimonial5.webp';
import Testimonial6 from '../../../../public/img/dry-january/testimonial6.webp';
import theme from '../../../theme';

export default function HomeTestimonials(): JSX.Element {
    const TestimonialBox = styled(SunnysideGrid)(({ theme }) => ({
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 80px 50px !important',
        margin: '0 auto',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1380px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 27px 50px !important',
            flexDirection: 'column',
            '& .yellowButton': {
                marginBottom: '25px'
            }
        },
        '& .grid': {
            placeContent: 'space-evenly',
            margin: '0 auto',
            rowGap: '50px',
            columnGap: '30px',
            marginBottom: '30px',
            [theme.breakpoints.down('md')]: {
                width: '90%',
            },
            '& .testimonial': {
                maxWidth: '350px',
                width: '30%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.down('md')]: {
                    maxWidth: '350px',
                    width: '100%'
                },
                '& .imageBox': {
                    width: '80px',
                    height: '80px',
                    borderRadius: '50%',
                },
                '& .quote': {
                    fontSize: '18px',
                    lineHeight: '25px',
                    fontWeight: 600,
                    fontFamily: 'Poppins',
                    color: 'black',
                    textAlign: 'center'
                },
                '& .name': {
                    fontSize: '14px',
                    lineHeight: '32px',
                    color: '#999',
                    fontFamily: 'Poppins',
                    fontWeight: 700
                }
            }
        },
        '& .yellowButton': {
            width: 'calc(100% - 40px)'
        }
    }));

    return (
        <Box
            style={{
                width: '100%',
            }}
        >
            <TestimonialBox>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        style={{
                            height: '1px',
                            backgroundColor: '#EEE',
                            margin: '0 0 50px'
                        }}
                    />
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 600,
                            letterSpacing: '10%',
                            textAlign: 'center',
                            color: 'black',
                            textTransform: 'uppercase',
                            marginBottom: '8px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '12px',
                                lineHeight: '16px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '15px',
                                lineHeight: '22px',
                            },
                        }}
                    >
                    Testimonials
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '46px',
                            lineHeight: '52px',
                            fontWeight: 800,
                            textAlign: 'center',
                            color: 'black',
                            marginBottom: '20px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '30px',
                                lineHeight: '32px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '46px',
                                lineHeight: '52px',
                            },
                        }}
                    >
                    The proof is in the reviews.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 400,
                            textAlign: 'center',
                            color: 'black',
                            width: '75%',
                            margin: '0 auto 50px',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '13px',
                                lineHeight: '20px',
                                width: '100%',
                                margin: '0 0 50px',
                            },
                            [theme.breakpoints.up('md')]: {
                                fontSize: '18px',
                                lineHeight: '26px',
                            },
                        }}
                    >
                    We’re incredibly proud of the impact we’ve had on over 250,000 lives, and love to hear our members tell us their stories. Hopefully it inspires you as well.
                    </Typography>
                    <Grid container className={'grid'} columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 5 }}>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial1} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“No shaming or hassling, very positive and motivating.“</Typography>
                            <Typography variant={'body1'} className={'name'}>Romster</Typography>
                        </Box>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial4} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“I&apos;m now a moderate drinker, almost a social drinker.”</Typography>
                            <Typography variant={'body1'} className={'name'}>Ted</Typography>
                        </Box>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial2} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“The accountability is a big part of what works for me.”</Typography>
                            <Typography variant={'body1'} className={'name'}>CeeCee</Typography>
                        </Box>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial5} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“I&apos;ve since lost weight and saved a ton of money.”</Typography>
                            <Typography variant={'body1'} className={'name'}>Abby</Typography>
                        </Box>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial3} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“I still enjoy a couple drinks here and there.”</Typography>
                            <Typography variant={'body1'} className={'name'}>Jon K</Typography>
                        </Box>
                        <Box className={'testimonial'}>
                            <Box className={'imageBox'}><NextImage alt={'Picture of a Sunnyside customer'} src={Testimonial6} /></Box>
                            <Typography variant={'h2'} className={'quote'}>“I’ve cut my drinking back to 1/3 of what it was.”</Typography>
                            <Typography variant={'body1'} className={'name'}>Bonnie</Typography>
                        </Box>
                    </Grid>
                    <SunnysideButton
                        hovercolor={'#F2D529'}
                        url={diagnosticLink()}
                        textcolor={'#410C6A'}
                        fullWidth
                        height={'50px'}
                        sx={{
                            backgroundColor: '#FFE636',
                            fontWeight: 700,
                            borderRadius: '4px',
                            fontFamily: 'Poppins',
                            maxWidth: '411px',
                            margin: '0 auto',
                            [theme.breakpoints.down(320)]: 
                        {
                            fontSize: '20px',
                            lineHeight: '36px',
                        },
                            [theme.breakpoints.up(320)]: 
                        {
                            fontSize: '20px',
                            lineHeight: '36px'
                        },
                        }}>
                        {'Join 250,000+ members'}
                    </SunnysideButton>
                </Box>
            </TestimonialBox>
        </Box>
    );
}