import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import NextImage from 'next/image';

import SunnysideGrid from '../../../components_sunnyside/grid';
import SunnysideButton from '../../../components_sunnyside/button';
import { diagnosticLink } from '../../../utils/diagnosticLinks';
import Community from '../../../../public/img/sunnyside/community.webp';
import Tracking from '../../../../public/img/sunnyside/tracking.webp';
import Coaching from '../../../../public/img/sunnyside/coaching.webp';
import Progress from '../../../../public/img/sunnyside/progress.webp';
import Challenges from '../../../../public/img/sunnyside/challenges.webp';
import Planning from '../../../../public/img/sunnyside/planning.webp';

export default function HomeFeatures(): JSX.Element {
    const theme = useTheme();
    const FeaturesBox = styled(SunnysideGrid)(({ theme }) => ({
        marginTop: '50px',
        padding: '0 150px',
        margin: '0 auto',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1380px',
        },
        [theme.breakpoints.down('md')]: {
            padding: '0px 27px',
        }
    }));

    return (
        <Box
            style={{
                width: '100%',
            }}
        >
            <FeaturesBox
                maxwidthwrapper
            >
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        letterSpacing: '10%',
                        textAlign: 'center',
                        color: 'black',
                        textTransform: 'uppercase',
                        marginBottom: '8px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '12px',
                            lineHeight: '16px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '15px',
                            lineHeight: '22px',
                        },
                    }}
                >
                The Features
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontSize: '46px',
                        lineHeight: '52px',
                        fontWeight: 800,
                        textAlign: 'center',
                        color: 'black',
                        marginBottom: '20px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '30px',
                            lineHeight: '32px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '46px',
                            lineHeight: '52px',
                        },
                    }}
                >
                What you get when you join for free
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                        textAlign: 'center',
                        color: 'black',
                        width: '75%',
                        margin: '0 auto 50px',
                        [theme.breakpoints.down('md')]: {
                            fontSize: '13px',
                            lineHeight: '20px',
                            width: '100%',
                            margin: '0 0 50px',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '18px',
                            lineHeight: '26px',
                        },
                    }}
                >
                Backed by a unique SMS-based coaching system, and a vibrant community, we’ve been honing an effective, low-pressure way to help you reduce your drinking since 2020!
                </Typography>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '30px 0'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                            }
                        }}
                    >
                        <NextImage
                            src={Community}
                            alt='Illustration showing a community text post with comments and likes.' 
                            width={359}
                            height={349}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '540px',
                            marginBottom: '50px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Community
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Give and get strength and motivation from the loving community.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                            The Sunnyside community is made up of people just like you who are looking to cut back on their drinking, but don’t want to feel any shame or guilt. Celebrate your wins, give advice, receive support, and work together toward your goals!
                        </Typography>
                        <SunnysideButton
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            hovercolor={'#F2D529'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Join the community'}
                        </SunnysideButton>
                    </Box>
                </Box>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '30px 0'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            display: 'flex',
                            alignSelf: 'flex-end',
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                borderBottom: '1px solid #EEE',
                            }
                        }}
                    >
                        <NextImage
                            src={Tracking}
                            alt='Screenshot of the tracking screen in the Sunnyside app.' 
                            width={336}
                            height={315}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '550px',
                            marginBottom: '80px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: '30px',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Tracking
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Logging your drinks is the foundation to long-term habit change.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                        Tracking your drinks will help you understand how much you drink and identify triggers. With Sunnyside it couldn’t be easier. Text an emoji when you’re having a drink, or open the app and update your previous day’s total. It’s fun and takes just a second.
                        </Typography>
                        <SunnysideButton
                            hovercolor={'#F2D529'}
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Start tracking'}
                        </SunnysideButton>
                    </Box>
                </Box>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '0 0 30px'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                            }
                        }}
                    >
                        <NextImage
                            src={Coaching}
                            alt='Screenshot of a conversation between a Sunnyside coach and customer.' 
                            width={280}
                            height={223}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '560px',
                            marginBottom: '50px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Coaching
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Non-judgmental coaching from real humans who care, not AI bots.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                        Our trained team of experts are available to help you with anything you need to hit your alcohol moderation goals. Need help in a social setting? Want to vent about your day? Need strategies for moderation? We got you.
                        </Typography>
                        <SunnysideButton
                            hovercolor={'#F2D529'}
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Meet your coaches'}
                        </SunnysideButton>
                    </Box>
                </Box>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '30px 0'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                            }
                        }}
                    >
                        <NextImage
                            src={Progress}
                            alt='Screenshot of the progress screen in the Sunnyside app showing drinks, calories and money saved.' 
                            width={300}
                            height={236}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '560px',
                            marginBottom: '50px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Progress
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Measure your progress in the terms that matter most to you, not just the drinks.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                        Cutting back on your drinking even just a little has a positive impact on nearly every area of your health, and we’ll help you see that with simple, clear analytics. Whether you care about sleep, money, or calories, we got you covered.
                        </Typography>
                        <SunnysideButton
                            hovercolor={'#F2D529'}
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Start making progress'}
                        </SunnysideButton>
                    </Box>
                </Box>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '30px 0'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                            }
                        }}
                    >
                        <NextImage
                            src={Challenges}
                            alt='Illustrations of the different types of challenges in the Sunnyside app.' 
                            width={272}
                            height={262}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '550px',
                            marginBottom: '50px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Challenges
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Switch things up with community-driven monthly challenges.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                        Want to take 30 days off of drinking? Maybe try not drinking on weekdays? Or how about cutting your drinks in half? Challenges give you a fun way to take things to the next level and have a team with you along the way.
                        </Typography>
                        <SunnysideButton
                            hovercolor={'#F2D529'}
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Join a challenge'}
                        </SunnysideButton>
                    </Box>
                </Box>
                <Box
                    style={{
                        height: '1px',
                        backgroundColor: '#EEE',
                        margin: '30px 0'
                    }}
                />
                <Box
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        [theme.breakpoints.down('md')]: {
                            display: 'flex',
                            flexDirection: 'column',
                            gridTemplateColumns: 'unset',
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: 'fit-content',
                            gridRowStart: 1,
                            gridColumnStart: 2,
                            display: 'flex',
                            alignSelf: 'flex-end',
                            [theme.breakpoints.down('md')]: {
                                margin: '0 auto 30px',
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                borderBottom: '1px solid #EEE',
                            }
                        }}
                    >
                        <NextImage
                            src={Planning}
                            alt='Screenshot of the weekly planning screen in the Sunnyside app.' 
                            width={336}
                            height={333}
                        />
                    </Box>
                    <Box
                        sx={{
                            gridRowStart: 1,
                            gridColumnStart: 1,
                            maxWidth: '540px',
                            marginBottom: '80px',
                            [theme.breakpoints.down('md')]: {
                                gridRowStart: 'unset',
                                gridColumnStart: 'unset',
                                marginBottom: '30px',
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#B05DF1',
                                letterSpacing: '10%',
                                textTransform: 'uppercase',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '12px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '36px',
                                }
                            }}
                        >
                        Planning
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                                color: '#333',
                                letterSpacing: '-2%',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '24px',
                                    lineHeight: '30px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '32px',
                                    lineHeight: '38px',
                                }
                            }}
                        >
                        Design your weekly plan based on advice from Sunnyside.
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                letterSpacing: '-2%',
                                color: '#3E3E3E',
                                marginBottom: '20px',
                                [theme.breakpoints.down('md')]: {
                                    fontSize: '13px',
                                    lineHeight: '20px',
                                },
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '16px',
                                    lineHeight: '26px',
                                }
                            }}
                        >
                        Pre-commit to the week ahead with your Sunnyside plan. It’s a chance to prioritize your healthy activities and habits, and be intentional with when and how much you drink. You’re always in control and can adjust it at any time.
                        </Typography>
                        <SunnysideButton
                            hovercolor={'#F2D529'}
                            url={diagnosticLink()}
                            textcolor={'#410C6A'}
                            fullWidth
                            height={'50px'}
                            sx={{
                                backgroundColor: '#FFE636',
                                fontWeight: 700,
                                borderRadius: '4px',
                                fontFamily: 'Poppins',
                                [theme.breakpoints.down(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                },
                                [theme.breakpoints.up(320)]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px'
                                },
                                [theme.breakpoints.down('md')]: 
                                {
                                    marginBottom: '20px',
                                },
                                [theme.breakpoints.up('md')]: 
                                {
                                    fontSize: '20px',
                                    lineHeight: '36px',
                                    maxWidth: '283px',
                                },
                            }}>
                            {'Get your first plan'}
                        </SunnysideButton>
                    </Box>
                </Box>
            </FeaturesBox>
        </Box>
    );
}