import React, { forwardRef, useState } from 'react';
import { Box } from '@mui/material';
import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import router, { useRouter } from 'next/router';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { colors } from '../../styles/_colors';
import SunnysidePineLogo from '../../../public/icons/sunnyside/sunnyside-logo-pine-2';
import SunnysideButton from '../button';
import { Media, MediaContextProvider } from '../../styles/media';
import { diagnosticLink } from '../../utils/diagnosticLinks';
import SunnysideSelector from '../selector';
import theme from '../../theme';
import SunnysideLink from '../link';

export default function SunnysideHeader(): JSX.Element {
    const [logoUrl] = useState('/');
    const isStaging = process.env.NEXT_PUBLIC_NENV === 'staging';
    const isDevelopment = process.env.NEXT_PUBLIC_NENV === 'staging';

    const HeaderGrid = styled(Grid)<GridProps>(({ theme }) => ({
        background: 'white',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 32,
        paddingBottom: 20,
        margin: '0 auto',
        [theme.breakpoints.up('xl')]: {
            maxWidth: '1380px',
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: colors.beige,
        },
        ['& .link']: {
            backgroundColor: 'red',
        }
    }));

    type LogoProps = {
        onClick?: () => void,
        href?: string
    }
    const Logo = forwardRef<HTMLAnchorElement, LogoProps>(({ onClick, href }, ref) => {
        return (
            <MediaContextProvider>
                <Media lessThan='sm'>
                    <a href={href} onClick={onClick} ref={ref}>
                        <SunnysidePineLogo
                            width={126}
                            height={40}
                        />
                    </a>
                </Media>
                <Media greaterThanOrEqual='sm'>
                    <a href={href} onClick={onClick} ref={ref}>
                        <SunnysidePineLogo
                            width={168}
                            height={53}
                        />
                    </a>
                </Media>
            </MediaContextProvider>
        );
    });
    Logo.displayName = 'Logo';
    Logo.propTypes = {
        onClick: PropTypes.func,
        href: PropTypes.string
    };

    const options = [
        'About',
        'Blog',
        'Reviews',
        'For Organizations',
        'Log In',
    ];

    const optionLinks = [
        '/about',
        '/blog',
        '/reviews',
        'https://get.sunnyside.co/organizations',
        isDevelopment ? 'http://app.dev.sunnyside.co/login/phone' : (isStaging ? 'https://app.staging.sunnyside.co/login/phone' : 'https://app.sunnyside.co/login/phone')
    ];

    const mobileOptions = options.concat('Get Started');
    const mobileLinks = optionLinks.concat(diagnosticLink());

    const handleOnClick = (event) => {
        if (event.target && event.target.text === 'Blog') {
            router.push('/blog');
        }
    };

    const clientRouter = useRouter();
    const pathname = clientRouter.asPath;
    const checkActive = (option) => {
        return pathname.includes(option.toLowerCase());
    };

    return (
        <Box
            style={{
                width: '100%'
            }}
        >
            <HeaderGrid
                role='navigation'
            >
                <Box
                    sx={{
                        marginLeft: '90px',
                        [theme.breakpoints.down('sm')]: {
                            margin: '0 auto'
                        }
                    }}>
                    <Link href={logoUrl} passHref prefetch={false} aria-label={'Link to Sunnyside homepage'}>
                        <Logo />
                    </Link>
                </Box>
                <MediaContextProvider>
                    <Media lessThan="md">
                        <Box
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0
                            }}
                        >
                            <SunnysideSelector
                                options={mobileOptions}
                                optionLinks={mobileLinks}
                                menuWidth={'calc(100vw - 2.1875rem)'}
                                onClick={handleOnClick}
                                icon
                                iconColor={'#B8A664'}
                            />
                        </Box>
                    </Media>
                    <Media greaterThanOrEqual="md">
                        <Box
                            style={{
                                marginRight: '90px'
                            }}
                        >
                            {options.map((option, index) => {
                                return (
                                    <SunnysideLink
                                        key={index}
                                        onClick={handleOnClick}
                                        href={optionLinks[index]}
                                        fontFamily={'Poppins'}
                                        color={'#125782'}
                                        padding={'0 20px'}
                                        bold={'true'}
                                        className={['link', checkActive(option) && 'active-nav-link']}
                                    >
                                        {option}
                                    </SunnysideLink>
                                );
                            })}
                            <SunnysideButton
                                padding='6px 26px'
                                width='fit-content'
                                href={diagnosticLink()}
                                borderRadius={'3px'}
                                margin={'0 0 0 20px'}
                                bgcolor={'#125782'}
                                hovercolor={'#0E4C7C'}
                                fontSize={'18px'}
                                style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 800
                                }}
                                textcolor={colors.cloud}
                            >
                                SIGN UP
                            </SunnysideButton>
                        </Box>
                    </Media>
                </MediaContextProvider>
            </HeaderGrid>
        </Box>
    );
}
