import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import React from 'react';
import NextImage from 'next/image';

import HrishikeshBelani from '../../../../public/img/sunnyside/advisors/hirshikesh_belani.webp';
import KateBonham from '../../../../public/img/sunnyside/advisors/kate_bonham.webp';
import NirEyal from '../../../../public/img/sunnyside/advisors/nir_eyal.webp';
import AdiJaffe from '../../../../public/img/sunnyside/advisors/adi_jaffe.webp';
import SunnysideGrid from '../../../components_sunnyside/grid';
import theme from '../../../theme';

export default function Home(): JSX.Element {
    return (
        <Box
            style={{
                width: '100%',
                backgroundColor: '#1A6899'
            }}
        >
            <SunnysideGrid
                style={{ padding: 0 }}
                sx={{
                    margin: '0 auto',
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: '1380px',
                    },
                }}
            >
                <Box
                    style={{
                        width: '100%',
                        backgroundColor: '#1A6899',
                        padding: '30px 0',
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: 500,
                            textAlign: 'center',
                            color: 'white',
                            marginBottom: '40px',
                        }}
                    >
                    Sunnyside’s expert advisors:
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '0 200px',
                            [theme.breakpoints.down('lg')]: {
                                padding: '0 50px',
                            },
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                padding: 0
                            }
                        }}
                    >
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                width: 'fit-content'
                            }}
                        >
                            <Box
                                style={{
                                    width: '83px',
                                    height: '83px',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                }}
                            >
                                <NextImage src={HrishikeshBelani}
                                    alt='Head shot of Dr. Hrishikesh Belani'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '200px',
                                    marginTop: '16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        marginBottom: '8px'
                                    }}
                                >
                            Dr. Hrishikesh Belani
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        fontWeight: 500,
                                        color: '#84B7D7',
                                        textAlign: 'center',
                                        marginBottom: '40px',
                                    }}
                                >
                            Associate Medical Director of the South Los Angeles Health Center Group
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                width: 'fit-content'
                            }}
                        >
                            <Box
                                style={{
                                    width: '83px',
                                    height: '83px',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                }}
                            >
                                <NextImage src={KateBonham}
                                    alt='Head shot of Kate Bonham'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '200px',
                                    marginTop: '16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        marginBottom: '8px'
                                    }}
                                >
                            Kate Bonham
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        fontWeight: 500,
                                        color: '#84B7D7',
                                        textAlign: 'center',
                                        marginBottom: '40px',
                                    }}
                                >
                            MS, LPCC, LADC - Mental Health and Chemical Health Specialist
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                width: 'fit-content'
                            }}
                        >
                            <Box
                                style={{
                                    width: '83px',
                                    height: '83px',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                }}
                            >
                                <NextImage src={NirEyal}
                                    alt='Head shot of Nir Eyal'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '200px',
                                    marginTop: '16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        marginBottom: '8px'
                                    }}
                                >
                            Nir Eyal
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        fontWeight: 500,
                                        color: '#84B7D7',
                                        textAlign: 'center',
                                        marginBottom: '40px',
                                    }}
                                >
                            Author of Hooked and Indistractable
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                margin: '0 auto',
                                width: 'fit-content'
                            }}
                        >
                            <Box
                                style={{
                                    width: '83px',
                                    height: '83px',
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    margin: '0 auto'
                                }}
                            >
                                <NextImage src={AdiJaffe}
                                    alt='Head shot of Adi Jaffe'
                                />
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '200px',
                                    marginTop: '16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        marginBottom: '8px'
                                    }}
                                >
                            Adi Jaffe
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        fontWeight: 500,
                                        color: '#84B7D7',
                                        textAlign: 'center',
                                        marginBottom: '40px',
                                    }}
                                >
                            CEO of IGNTD and Author of The Abstinence Myth
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SunnysideGrid>
        </Box>
    );
}