import React, { useEffect, useState } from 'react';
import DesktopFooter from './desktop_footer';
import MobileFooter from './mobile_footer';
import SunnysideGrid from '../grid';
import { Media, MediaContextProvider } from '../../styles/media';
import { getFooterData } from '../../logic/api';

export default function SunnysideFooter(): JSX.Element {
    const [footerData, setFooterData] = useState<any>();
    const getData = async() => {
        const data = await getFooterData();
        setFooterData(data);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <SunnysideGrid
            maxwidthwrapper
            style={{
                padding: '50px 27px'
            }}
        >
            <MediaContextProvider>
                <Media lessThan='md'>
                    <MobileFooter useCases={footerData?.useCases} />
                </Media>
                <Media greaterThanOrEqual='md'>
                    <DesktopFooter useCases={footerData?.useCases} />
                </Media>
            </MediaContextProvider>
        </SunnysideGrid>    
    );
}